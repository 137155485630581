var Slider = function() {
    var self = this;


    this.init = function() {
        self.initNivo();
        self.resizeServices();
    };

    this.initNivo = function() {
        var delays = [2000,8000,2000,8000,2000,8000];

        $(".home-slider__content").nivoSlider({
            effect: 'sliceDown,sliceUp',
            slices: 50,
            pauseTime: 50000,
            directionNav: true,
            afterChange: function () { setDelay() },
            afterLoad: function () { setDelay() },
            controlNav: false,
            pauseOnHover: false,
            prevText: '',
            nextText: ''
        });

        function setDelay() {
            var currentSlide = $(".home-slider__content").data("nivo:vars").currentSlide;
            setTimeout(function () {
                $(".home-slider__content").find('a.nivo-nextNav').click()
            }, delays[currentSlide]);

        }


        this.resizeServices = function() {

            /*
            $(document).ready(function() {
                $(".home-slider__services-wrapper").height($("#home-slider").height()-64);
            });
            $( window ).resize(function() {
                $(".home-slider__services-wrapper").height($("#home-slider").height()-64);
            });
            */

        }

    };

    this.init();


};