CNNA.BP = (function() {
    var _;
    var breakpoints = {
        'zero': 0,
        'xsmall': 320,
        'small': 768,
        'medium': 1024,
        'large': 1280,
        'xlarge': 1600
    };
    var currentBreakpoint;
    var changeBreakpointEvent = new Event('changeBreakpoint');

    return {
        init: function() {
            _ = this;
            _.bindEvents();
            _.checkBreakpoint();
        },

        bindEvents: function() {
            $(window).resize(_.windowResize);
        },

        windowResize: CNNA.Debounce(function() {
            _.checkBreakpoint();
        }, 250),

        checkBreakpoint: function() {
            var width = $(window).width();
            var newBreakpoint = 'zero';
            var keys = Object.keys(breakpoints);
            for (var i = 1; i <= keys.length; i++) {
                if(typeof breakpoints[keys[i+1]] != "undefined") {
                    if(width >= breakpoints[keys[i]] && width < breakpoints[keys[i+1]]) {
                        newBreakpoint = keys[i];
                    }
                } else {
                    if(width >= breakpoints[keys[i]]) {
                        newBreakpoint = keys[i];
                    }
                }
            }

            _.setBreakpoint(newBreakpoint);

        },

        setBreakpoint: function(breakpoint) {
            if (currentBreakpoint != breakpoint) {
                currentBreakpoint = breakpoint;
                document.dispatchEvent(changeBreakpointEvent);
            }
        },

        getCurrentBreakpoint: function() {
            return currentBreakpoint;
        },

        aboveBreakpoint: function(breakpoint) {
            if(breakpoints[currentBreakpoint] >= breakpoints[breakpoint]) {
                return true;
            }
            return false;
        },
        belowBreakpoint: function(breakpoint) {
            if(breakpoints[currentBreakpoint] < breakpoints[breakpoint]) {
                return true;
            }
            return false;
        }
    }
})();