(function(root) {
    console.log("CNNA");

    root.CNNA = {
        init: function() {
            CNNA.BP.init();

            // var heroAnimation = new HeroAnimation();
            var slider = new Slider();
            var menu = new Menu();
            var primaryNav = new PrimaryNav();
            var cnnaFluidBox = new CNNAFluidBox();
        }
    };

}(window));