var PrimaryNav = function() {
    var self = this;
    var primaryNav = $('#primary-nav');
    var parentItems = primaryNav.find('.primary-nav__parent-item');

    this.init = function() {
        self.bindEvents();
    };

    this.bindEvents = function() {
        parentItems.each(function() {
            $(this).on('mouseenter', self.openMenu);
            $(this).on('mouseleave', self.closeMenus);
        });

    };

    this.openMenu = function() {
        self.closeMenus();
        $(this).addClass('primary-nav__parent-item--open');
        $(this).find('.primary-nav__children-list').addClass('primary-nav__children-list--open');
        $(this).find('.primary-nav__parent-link').addClass('primary-nav__parent-link--open');
    };

    this.closeMenus = function() {
        $('.primary-nav__parent-item').removeClass('primary-nav__parent-item--open');
        $('.primary-nav__children-list').removeClass('primary-nav__children-list--open');
        $('.primary-nav__parent-link').removeClass('primary-nav__parent-link--open');
    };

    this.init();
};