var Menu = function() {
    var self = this;
    
    this.init = function() {
        console.log("Menu init");
        
        $('#navigation-button').sidr({
            name: 'navigation-menu',
            side: 'right',
            displace: false
        });

        $("#navigation-close-button").on("click", function(e) {
            e.preventDefault();
            $.sidr('close', 'navigation-menu');
        });

        $(".menu-section-toggle").on("click", function(e) {
            e.preventDefault();
            $(this).next("ul").slideToggle("fast");
        });

        $(".menu-subsection").hide();
    };

    this.init();
};